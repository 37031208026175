import { useEffect, useState } from "react";
import { getCountryNameByCode } from "../utility/Countries";
import { withRouter } from "./withRouter";
import { useFeatureFlags } from "../hooks/useFeatureFlags";
import "./PostSignup.scss";

interface IPostSignUpProps {
  router: {
    location: {
      search: string;
      state?: {
        signUpFormValues: any;
      };
    };
  };
}

const PostSignUp = (props: IPostSignUpProps) => {
  const flags = useFeatureFlags().getFlags(["pardot_logging"]);
  const [iFrameURL, setIFrameURL] = useState("");

  const getComponentName = () => {
    return "components-PostSignup";
  };

  useEffect(() => {
    const fetchData = async () => {
      if (
        props.router.location.search &&
        props.router.location.search.includes("signup") &&
        props.router.location.state?.signUpFormValues &&
        iFrameURL === ""
      ) {
        await generateIFrameUrl();
      }
    };
    fetchData();
  }, []); // eslint-disable-line

  const generateIFrameUrl = async () => {
    if (flags.pardot_logging.enabled) {
      const formValues = { ...props.router.location.state!.signUpFormValues };
      Object.keys(formValues).map((param) => {
        formValues[param] = encodeURIComponent(formValues[param]);
      });
      let countryName = getCountryNameByCode(formValues.Country);
      countryName = encodeURIComponent(countryName ?? "");

      let iframeURL = `https://go.cdata.com/l/933343/2022-02-17/bcd1?email=${formValues.ContactEmail}&company=${formValues.Organization}&firstname=${formValues.ContactFirstName}&lastname=${formValues.ContactLastName}&country=${countryName}&accountname=${formValues.AccountName}&accountid=${formValues.Id}`;

      const lang = navigator.language;
      if (lang === "ja") {
        iframeURL = iframeURL + "&lang=" + lang;
      }

      setIFrameURL(iframeURL);
    }
  };

  return (
    <iframe
      className={getComponentName()}
      scrolling="no"
      frameBorder="0"
      src={iFrameURL}
      aria-label="contact form"
    ></iframe>
  );
};

export default withRouter(PostSignUp);
